import { Footer } from 'flowbite-react'
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs'
import { HiLocationMarker, HiMail, HiPhone } from 'react-icons/hi'
import logo from '../../assets/icons/okapi.png'

export default function Component() {
   return (
      <Footer className="max-w-screen-xl">
         <div className="w-full ">
            <div className="grid w-full  grid-cols-1 gap-8 px-6 py-8 md:grid-cols-3">
               <div>
                  <Footer.Title title="Approps de nous" />
                  <Footer.LinkGroup col>
                     <Footer.Link href="#" className="font-semibold">
                        <img src={logo} alt="logo" className="h-20" />
                     </Footer.Link>
                     <Footer.Brand href="#">
                        Notre mission est de vous offrir une expérience unique
                        et captivante dans certains des endroits les plus
                        accent-color fascinants de la République Démocratique du
                        Congo et principalement au Nord-Kivu et sud Kivu.
                     </Footer.Brand>
                     {/* <Footer.Link href="#">Careers</Footer.Link>
                     <Footer.Link href="#">Privacy Policy</Footer.Link>
                     <Footer.Link href="#">Terms &amp; Conditions</Footer.Link> */}
                  </Footer.LinkGroup>
               </div>
               <div>
                  <Footer.Title title="Nos Services" />
                  <Footer.LinkGroup col>
                     <Footer.Link href="/inscriptions">Inscription</Footer.Link>
                     <Footer.Link href="#">Tourisme</Footer.Link>
                     <Footer.Link href="#">Obtension Passport</Footer.Link>
                     <Footer.Link href="#">Obtension Visa</Footer.Link>
                     <Footer.Link href="#">Librairie</Footer.Link>
                  </Footer.LinkGroup>
               </div>
               <div>
                  <Footer.Title title="Contacts" />
                  <Footer.LinkGroup col>
                     <Footer.Link className="flex flex-row items-center gap-2">
                        <span>
                           <HiLocationMarker className="inline text-5" />
                        </span>
                        <span>
                           {' '}
                           28, Av Patrice Emery Lumuba/ Commune d'Ibanda/
                           Quartier Ndendere/ Bukavu/ Sud-Kivu
                        </span>
                     </Footer.Link>
                     <Footer.Link
                        href="#"
                        className="flex flex-row items-center gap-2"
                     >
                        <span>
                           <HiPhone className="inline text-5" />
                        </span>
                        <span> (+243) 980826800, 899399601, 837053917</span>
                     </Footer.Link>
                     <Footer.Link
                        href="mailto:bonjour@okapitouristiques.com"
                        className="flex flex-row items-center gap-2"
                     >
                        <span>
                           <HiMail className="inline text-5" />
                        </span>
                        <span> okapitouristiqueofficiel@gmail.com</span>
                     </Footer.Link>
                  </Footer.LinkGroup>
               </div>
            </div>
            <div className="flex w-full items-center justify-center px-4 py-4">
               <span className="text-8 font-bold italic text-primary">
                  Tourismons la +243
               </span>
            </div>
            <div className="w-full bg-primary px-4 py-6 sm:flex sm:items-center sm:justify-between">
               <div className="flex items-center gap-2">
                  <Footer.Copyright
                     href="#"
                     by="OkapiTouristique™"
                     year={new Date().getFullYear()}
                     className="text-darktext "
                  />

                  {/* <span className="text-white font-light">
                     Made with ❤️ at{' '}
                     <a
                        href="http://kwetutech.com"
                        target="blank"
                        className="underline hover:font-semibold"
                     >
                        KwetuTech™️
                     </a>
                  </span> */}
               </div>

               <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                  <Footer.Icon
                     href="https://www.facebook.com/okapitouristique243"
                     icon={BsFacebook}
                     className="text-darktext "
                  />
                  <Footer.Icon
                     href="https://www.instagram.com/okapitouristique243"
                     icon={BsInstagram}
                     className="text-darktext "
                  />
                  <Footer.Icon
                     href="https://twitter.com/okapitouristiqu"
                     icon={BsTwitter}
                     className="text-darktext "
                  />
               </div>
            </div>
         </div>
      </Footer>
   )
}
